<template>
  <div class="door">
    <div class="btn" v-if="qrCodeNumber > 0">
      <el-button type="primary" @click="onExport()">公区门禁码导出</el-button>
    </div>
    <template v-if="tableData.length > 0">
      <div class="door-list">
        <li class="door-item" v-for="item in tableData" :key="item.deviceId">
          <p class="p-top">
            <span>{{ item.deviceName }}</span>
            <el-tag
              :type="item.deviceStatus ? 'success' : 'danger'"
              v-if="item.gatewayStatus == 1"
              >{{ deviceStatusObj[item.deviceStatus] }}</el-tag
            >
            <el-tag type="danger" v-else>网关离线</el-tag>
          </p>
          <P class="p-mid">位置：{{ item.deviceAddress }}</P>
          <p class="p-mid">外部地址：{{ item.deviceNumber }}</p>
          <div
            class="p-foot"
            v-for="it in item.subDeviceVoList"
            :key="it.deviceId"
          >
            <el-button type="primary" plain @click="onOpen(it)"
              >远程开门</el-button
            >
            <el-switch
              v-model="it.openStatus"
              active-text="常开"
              :active-value="1"
              :inactive-value="0"
              @change="onAlwaysOpen(it)"
            ></el-switch>
          </div>
        </li>
      </div>
    </template>
    <el-empty class="empty" v-else description="暂无设备信息"></el-empty>
    <!-- 导出进度弹框 -->
    <el-dialog
      title="位置码导出"
      :visible.sync="exportShow"
      center
      :close-on-click-modal="false"
      append-to-body
      width="20%"
    >
      <div class="export">
        <el-progress
          class="mb15 export-success"
          type="circle"
          text-color="#13CE66"
          :percentage="100"
          status="success"
          v-if="progressInfo.complete"
        ></el-progress>
        <el-progress
          class="mb15"
          type="circle"
          color="#EF9807"
          :percentage="percentage"
          :format="format"
          v-else
        ></el-progress>
        <p class="mb15">
          <b style="color: #13ce66" v-if="progressInfo.complete">已完成</b>
          <b style="color: #ef9807" v-else>进行中</b>
        </p>
        <el-button v-if="this.downloadUrl" @click="onDown">下载</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getDeviceList,
  getDeviceOperate,
  getOpenDoor,
  goDealQrcode,
  goDownloadQrcode,
  getQrcodeProgress,
  getQrcodeCount,
} from "@/api/device/device";
import { deviceStatusObj, deviceStatusColor } from "@/db/objs";
export default {
  data() {
    return {
      deviceStatusObj,
      deviceStatusColor,
      tableData: [],
      tableParams: {
        page: 1,
        pageSize: 0,
        merchantId: "", //商户ID
        shopId: "", //店铺ID
        deviceType: "10", //设备类型
        gatewayId: "", //网关
        deviceStatus: "", //设备状态
        bindStatus: "1", //绑定状态
      },
      value: true,
      qrCodeNumber: 0,
      exportShow: false,
      percentage: 0,
      progressInfo: {
        complete: false,
        num: 0,
        total: 0,
      },
      downloadUrl: "",
    };
  },
  created() {
    let merchantShopId = window.localStorage.getItem(
      "playOne-store-merchantShopId"
    );
    if (merchantShopId) {
      this.tableParams.merchantId = JSON.parse(merchantShopId)[0];
      this.tableParams.shopId = JSON.parse(merchantShopId)[1];
    }
  },
  mounted() {
    this.getDeviceList();
    this.getQrcodeCount();
  },
  methods: {
    // 【监听】位置码下载
    onDown() {
      window.location.href = this.downloadUrl;
    },
    format() {
      return `${this.progressInfo.num}/${this.progressInfo.total}`;
    },
    // 【监听】位置码导出
    onExport() {
      this.exportShow = true;
      this.percentage = 0;
      this.progressInfo = {
        complete: false,
        num: 0,
        total: 0,
      };
      this.downloadUrl = "";
      this.goDealQrcode(); //处理位置码
    },
    // 处理位置码
    goDealQrcode() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      goDealQrcode(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message(res.data);
          this.getQrcodeProgress(); //获取处理进度
        }
      });
    },
    // 获取处理进度
    getQrcodeProgress() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      getQrcodeProgress(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.progressInfo = res.data;
          this.percentage = Math.round((res.data.num / res.data.total) * 100);
          if (res.data.complete) {
            this.goDownloadQrcode();
          } else {
            setTimeout(() => {
              this.getQrcodeProgress();
            }, 1000);
          }
        }
      });
    },
    // 【请求】获取下载链接
    goDownloadQrcode() {
      let data = {
        shopId: this.tableParams.shopId,
      };
      goDownloadQrcode(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.downloadUrl = res.data;
        }
      });
    },
    // 获取设备数量
    getQrcodeCount() {
      let params = {
        shopId: this.tableParams.shopId,
      };
      getQrcodeCount(params).then((res) => {
        if (res.isSuccess == "yes") {
          this.qrCodeNumber = res.data;
        }
      });
    },
    // 【请求】设备列表
    getDeviceList() {
      let data = this.tableParams;
      getDeviceList(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.tableData = res.data.list;
        }
      });
    },

    // 【请求】设备控制
    getDeviceOperate(data) {
      getDeviceOperate(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getDeviceList();
        }
      });
    },

    // 【请求】门禁常开
    getOpenDoor(data) {
      getOpenDoor(data).then((res) => {
        if (res.isSuccess == "yes") {
          this.$message({
            message: res.message,
            type: "success",
          });
          this.getDeviceList();
        }
      });
    },

    // 【请求】远程开门
    onOpen(it) {
      this.$confirm("你确定要远程开门吗?", "远程开门", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "error",
      }).then(() => {
        let data = {
          deviceId: it.deviceId,
          command: "On",
        };
        this.getDeviceOperate(data);
      });
    },

    // 【请求】常开
    onAlwaysOpen(it) {
      let data = {
        deviceId: it.deviceId,
        openStatus: it.openStatus,
      };
      this.getOpenDoor(data);
    },
  },
};
</script>


<style lang="scss" scoped>
.btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 0.15rem;
}
.door-list {
  display: flex;
  flex-wrap: wrap;
}

.door-item {
  background: #fff;
  display: inline-flex;
  flex-direction: column;
  margin: 0.15rem 0.15rem 0 0;
  border-radius: 0.1rem;
  width: calc((100% - 0.6rem) / 5);
}

.door-item:nth-child(5n) {
  margin-right: 0;
}

p {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.p-top {
  padding: 0.15rem;
  margin-bottom: 0.15rem;
  border-bottom: 1px solid #f0f2f5;
}

.p-mid {
  color: #999;
  padding: 0.05rem 0.15rem;
}

.p-foot {
  padding: 0.15rem;
  margin-top: 0.15rem;
  border-top: 1px solid #f0f2f5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.empty {
  width: 100%;
}
.export {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
</style>